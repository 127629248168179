<template>
    <div class="container" id="app">
        <div v-if="!submitted && validHash || submitted == null">
            <!--<h1 class="mb-3">Ako hodnotíte vitalishop.sk na základe vášho nákupu?</h1>-->
            <h1 class="mb-3">Ako hodnotíte vitalishop.sk po vašom nákupe?</h1>

            <!--<p>Povedzte nám prosím svoj názor Vaša spätna väzba nám pomôže zlepšiť služby a ostatným zákazníkom uľahčí výber obchodu. Vyplnenie dotazníka vám zaberie len chvíľku.</p>-->
            <p>Prosím venujte nám pár sekúnd času. Vaša spätna väzba je pre nás veľmi dôležitá. Pomôže zlepšiť služby a ostatným zákazníkom uľahčí výber. Ďakujeme</p>

            <div class="mb-5">
                <h2>Odporúčali by ste obchod svojim známym?</h2>
                <!--<small>Boli ste spokojní so službami obchodu natoľko, že by ste sa nebáli ho odporúčiť aj svojím najbližším? Pri tejto otázke potrebujeme poznať vašu odpoveď.</small>-->

                <div class="form-check">
                    <input class="form-check-input" value="1" type="radio" id="recommended-true" v-model.number="form.recommended" v-on:change="saveForm(false)">
                    <label class="form-check-label" for="recommended-true">
                        ÁNO, odporúčam
                    </label>
                    </div>
                    <div class="form-check">
                    <input class="form-check-input" value="0" type="radio" id="recommended-false" v-model.number="form.recommended" v-on:change="saveForm(false)">
                    <label class="form-check-label" for="recommended-false">
                        NIE, neodporúčam
                    </label>
                </div>
            </div>

            <div class="mb-5" v-if="!form.recommended">
                <h2>Veľmi nás to mrzí, prosím napíšte stručne čo by sme mali zlepšiť</h2>
                <p>Či nám veríte alebo nie, vždy sa snažíme spraviť všetko pre to, aby ste boli spokojní alebo ašte lepšie nadšení. Nie vždy sa nám to ale podarí, sme predsa len ľudia. Budeme radi ak nám sem napíšete čo by sme mohli napraviť. A dúfame, že pri budúcom nákupe Vám budeme môcť ukázať, že sme sa zase o niečo posunuli.<br>Ďakujeme za dôveru.</p>
                <textarea rows="5" class="form-control" v-model="form.textNotRecommended" v-on:change="saveForm(false)"></textarea>
            </div>

            <template v-if="form.recommended">
                
                <!--
                <div class="mb-5">
                    <h2>V akom stave je Vaša objednávka?</h2>

                    Čakáte stále na dodanie, vyzdvihli ste si tovar osobne alebo Vám už dorazilo domov? Pri tejto otázke potrebujeme poznať vašu odpoveď.
                    <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                    <label class="form-check-label" for="flexRadioDefault1">
                        tovar mi bol doručený / osobne vyzdvihnutý
                    </label>
                    </div>

                    <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                    <label class="form-check-label" for="flexRadioDefault2">
                        tovar zatiaľ nedorazil
                    </label>
                    </div>
                </div>
                -->

                <!--
                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                <label class="form-check-label" for="flexRadioDefault2">
                    objednávku som zrušil(a)
                </label>
                </div>

                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                <label class="form-check-label" for="flexRadioDefault2">
                    objednávku zrušil obchod
                </label>
                </div>
                -->
                
                <div v-for="(question, questionIndex) in form.questions" v-bind:key="questionIndex" class="mb-5">
                    <h2>{{ question.text }}</h2>
                        <div class="smile-rating">
                            <img v-bind:class="{ selected_rating: form.questions[questionIndex].rating != null & form.questions[questionIndex].rating != 1 }" @click="changeRating(questionIndex, 1)" class="p-2" alt="Vue logo" src="images/rating-1.svg" width="50px">
                            <img v-bind:class="{ selected_rating: form.questions[questionIndex].rating != null & form.questions[questionIndex].rating != 2 }" @click="changeRating(questionIndex, 2)" class="p-2" alt="Vue logo" src="images/rating-2.svg" width="50px">
                            <img v-bind:class="{ selected_rating: form.questions[questionIndex].rating != null & form.questions[questionIndex].rating != 3 }" @click="changeRating(questionIndex, 3)" class="p-2" alt="Vue logo" src="images/rating-3.svg" width="50px">
                            <img v-bind:class="{ selected_rating: form.questions[questionIndex].rating != null & form.questions[questionIndex].rating != 4 }" @click="changeRating(questionIndex, 4)" class="p-2" alt="Vue logo" src="images/rating-4.svg" width="50px">
                            <img v-bind:class="{ selected_rating: form.questions[questionIndex].rating != null & form.questions[questionIndex].rating != 5 }" @click="changeRating(questionIndex, 5)" class="p-2" alt="Vue logo" src="images/rating-5.svg" width="50px">
                        </div>
                </div>

                <div class="mb-5">
                    <!--<h2>Podrobnejšie popíšte vaše skúsenosti s obchodom</h2>-->
                    <h2>Opíšte nám prosím pár slovami vašu skúsenosť</h2>
                    <!--Stručne popíšte, ako ste boli spokojný a prečo Pokiaľ sa vám teraz nechce rozpisovať, pridajte aspoň výhodu či nevýhodu. Pre pridanie kladných pripomienok využite tlačítko plus, pre pridanie negatívnych pripomienok tlačitko mínus.-->

                    <textarea v-model="form.textRecommended" class="form-control" v-on:change="saveForm(false)"></textarea>

                </div>
                
                <div class="mb-5">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-success mb-3" @click="addAdvantage">Pridať našu výhodu</button>

                            <div v-for="(advantage, advantageIndex) in form.advantages" v-bind:key="advantageIndex">
                                <div class="input-group mb-3">
                                    <input v-model="form.advantages[advantageIndex]" type="text" class="form-control border-success text-success" placeholder="Napíšte výhodu" aria-label="Napíšte výhodu" v-on:change="saveForm(false)">
                                    <button title="Vymazať" class="btn btn-outline-secondary" type="button" @click="removeAdvantage(advantageIndex)">x</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-danger mb-3" @click="addDisadvantage">Pridať našu nevýhodu</button>

                            <div v-for="(disadvantage, disadvantageIndex) in form.disadvantages" v-bind:key="disadvantageIndex">
                                <div class="input-group mb-3">
                                    <input v-model="form.disadvantages[disadvantageIndex]" type="text" class="form-control border-danger text-danger" placeholder="Napíšte nevýhodu" aria-label="Napíšte nevýhodu" v-on:change="saveForm(false)">
                                    <button title="Vymazať" class="btn btn-outline-secondary" type="button" @click="removeDisdvantage(disadvantageIndex)">x</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="mb-3">
                    <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Meno alebo prezývka:</label>
                        <input type="text" v-model="form.nick" class="form-control" id="exampleFormControlInput1" placeholder="Meno alebo prezývka" v-on:change="saveForm(false)">
                    </div>
                    (vyplnením súhlasíte so zverejnením mena alebo prezývky pri recenzii na našej stránke, pole nemusíte vyplniť ak chcete hodnotiť anonymne)
                </div>

            </template>
        
            <div class="row mb-3">
                <div class="col d-grid">
                    <button @click="submitReview" class="btn btn-success btn-block">Odoslať hodnotenie</button>
                </div>
            </div>
        </div>
        <div class="mt-5 text-center" v-else>
            <h1>Ďakujeme za vašu spätnú väzbu, vážime si že ste si našli čas.</h1>
            <h3>Prajeme všetko dobré.</h3>
            <h3>vitalishop.sk</h3>
        </div>

    </div>
</template>

<script>

import axios from 'axios';

export default {

    data : function() {
        return {
            submitted: null,
            validHash: true,
            orderId: null,
            orderHash: '',
            form: {
                recommended: null,
                textNotRecommended: "",
                textRecommended: "",
                questions: {
                    1 : {
                        text: "Aká je vaša celková spokojnosť s VitaliShop.sk ?",
                        rating: null,
                    },
                    2 : {
                        text: "Ako ste spokojný s dĺžkou dodacej lehoty?",
                        rating: null,
                    },
                    3 : {
                        text: "Ako hodnotíte kvalitu tovaru?",
                        rating: null,
                    },
                    4 : {
                        text: "Ako hodnotíte našu komunikáciu?",
                        rating: null,
                    },
                },
                advantages: [],
                disadvantages: [],
                nick: "",
            }

        }
    },
    methods: {
        addAdvantage: function(){
            if(this.form.advantages[this.form.advantages.length - 1] != '') {
                this.form.advantages.push('');
            }
        },
        addDisadvantage: function(){
           if(this.form.disadvantages[this.form.disadvantages.length - 1] != '') {
                this.form.disadvantages.push('')
            }
        },
        removeAdvantage: function(index) {
            this.form.advantages.splice(index, 1);
        },
        removeDisdvantage: function(index) {
            this.form.disadvantages.splice(index, 1);
        },
        submitReview: function() {
            this.submitted = true;
            this.saveForm(true); //true means this is called after submitt button was pressed, it is not automatic save while editing
        },
        saveForm: function(submitButton){
            //console.log('submit button:'+submitButton);
            axios.post(process.env.VUE_APP_ROOT_URL+'/review-controller.php?method=save_review', {
                order_id: this.orderId,
                order_hash: this.orderHash,
                nick: this.form.nick,
                submit_button: submitButton ? 1 : 0, //hodnotu true posiela len ak je stlacene sumbit tlacidlo
                recommended: this.form.recommended,
                text_recommended: this.form.textRecommended,
                text_not_recommended: this.form.textNotRecommended,
                advantages: this.form.advantages,
                disadvantages: this.form.disadvantages,
                questions: this.form.questions,
            });
            /*
            .then(response => {
                console.log(response);
                //this.validHash = response.data.isValidHash;
            })
            */
        },
        changeRating: function(question, rating) {
            this.form.questions[question].rating = rating;
            //console.log(this.form.questions[question].rating);
            this.saveForm(false);
            //alert("otazka "+question+" ma hodnotenie "+rating);
        },
        isSubmitted: function() {
            axios.get(process.env.VUE_APP_ROOT_URL+'/review-controller.php?method=is_submitted&order_id='+this.orderId)
            .then(response => {
                this.submitted = parseInt(response.data.isSubmitted);
                //console.log(response.data);
            });
        },
        isValidHash: function() {
            axios.get(process.env.VUE_APP_ROOT_URL+'/review-controller.php?method=validate_hash&order_id='+this.orderId+'&hash='+this.orderHash)
            .then(response => {
                //ak hash nie je validny tvarim sa ako keby bol form uz
                this.validHash = response.data.isValidHash;
                //this.submitted = response.data.isSubmitted;
                //console.log(response.data);
            }); 
        }

    },
    mounted: function() {

        //console.log(process.env.VUE_APP_ROOT_URL);
        
        const queryString = window.location.search; // musim dostat parametre z url, lebo tu nemam php a nemozem pouzit $_GET
        const urlParams = new URLSearchParams(queryString);

        this.orderId = parseInt(urlParams.get('o'));
        this.orderHash = urlParams.get('h');
        this.form.recommended = parseInt(urlParams.get('r'));

        //check if review was already submitted
        this.isSubmitted(this.orderId);
        this.isValidHash(this.orderId, this.orderHash);

        //console.log(this.submitted);
        if(this.validHash && !this.submitted) {
            //console.log('ukladam');
            this.saveForm(false);
        }
    }
}
</script>

<style>

.smile-rating img {
    cursor: pointer;
}

.selected_rating {
    filter: grayscale(100%);
    filter: opacity(20%);
}

</style>