import Vue from 'vue'
import App from './App.vue'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'popper.js';

//const bootstrap = require('bootstrap')
//import bootstrap from 'bootstrap'
//import popper from 'popper.js'

//import bootstrap from 'bootstrap'
//import './assets/app.scss'

//import 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css'

//Vue.use(bootstrap)
///Vue.use(kkk)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
